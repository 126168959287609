<template>
  <div class="select-sub">
    <top-bar back backroute="index" :title="$app.secure.clean($n.t('6.1'))" />
    <n-loader :loading="$var('load')" />
    <div class="container">
      <div class="new-order-wrap">
        <div v-for="item in subcategories" :key="item.id" class="item-wrap new-style"
             @click="$router.push({name: 'createApp', params:{categoryId: item.id}})">
          <div class="item new-style">
            <div class="img" v-html="item.image"></div>
            <div class="title">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageSelectSub',
  data() {
    return {
      subcategories: [],
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.$var('load', true)
      $api.categories.getChild(this.id).all().then((response) => {
        this.subcategories = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style scoped lang="scss">
.select-sub {
  .new-order-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -5px;


    .item-wrap {
      width: 50%;
      padding: 0 5px;
      margin-bottom: 15px;
    }

    .item {
      min-height: 180px;
      background: #FFF;
      border: 1px solid #D9D9D9;
      border-radius: 12px;
      height: 100%;
      overflow: hidden;
      margin-bottom: 15px;
      .img {
        margin-top: 5px;
        margin-bottom: 10px;
        height: 116px;
        ::v-deep svg {
          height: 100%;
          width: 100%;
        }
        display: flex;
        align-items: center;
        justify-content: center;

      }

      .title{
        padding: 0 16px;
        font-weight: bold;
        text-align: center;
      }
      .volume{
        padding: 0 16px;
        font-weight: bold;
      }
      .address{
        padding: 0 16px;
        font-size: 14px;
      }
      .author {
        padding: 0 16px;
        font-weight: bold;
      }
    }
  }
}
</style>
